import contentfulContent from "./contentfulData/data.json";
import { websiteContents, WebsiteContent } from "./websiteContent";

export interface WebsitePage {
  slug: string;
  htmlTitle: string;
  contentBlocks: WebsiteContent[];
}

const websitePageEntries = contentfulContent.entries.filter(
  (e) => e.sys.type === "Entry" && e.sys.contentType.sys.id === "websitePage"
);

export const websitePages = websitePageEntries.map(
  (p): WebsitePage => {
    const contents = p.fields.mainContent!["en-US"].map(
      (wc): WebsiteContent => {
        return websiteContents.find((c) => c.cid === wc.sys.id)!;
      }
    );
    return {
      slug: p.fields.slug!["en-US"],
      htmlTitle: p.fields.htmlTitle!["en-US"],
      contentBlocks: contents,
    };
  }
);
