




































import { Component, Vue, Prop } from "vue-property-decorator";
import { profiles, Profile } from "@/profiles";
import { websiteContents } from "@/websiteContent";
import multiDownload from "@/multiDownload";

@Component({})
export default class Profiles extends Vue {
  @Prop() showPrivate!: boolean;

  intro = websiteContents.find((w) => w.uid === "profiles-page-intro")!
    .contentMarkdown;
  outro = websiteContents.find((w) => w.uid === "profiles-page-outro")!
    .contentMarkdown;
  profiles = profiles.filter((p) =>
    this.showPrivate ? p.status === "private" : p.status != "private"
  );

  download(profile: Profile) {
    multiDownload(profile.downloadURLs, profile.downloadNames);
  }
}
