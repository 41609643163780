import contentfulContent from "./contentfulData/data.json";

export interface RGB {
  R: number;
  G: number;
  B: number;
}

export interface RisoInk {
  id: string;
  name: string;
  rgb: RGB;
  rgbString: string;
  // system id
  sysid: string;
}

function parseRGB(s: string): RGB {
  const vals = s.split(",").map((v) => parseInt(v));
  return { R: vals[0], G: vals[1], B: vals[2] };
}

export const risoInks = contentfulContent.entries
  .filter(
    (e) => e.sys.type === "Entry" && e.sys.contentType.sys.id === "risoInk"
  )
  .map((e) => {
    return {
      id: e.fields.id!["en-US"],
      name: e.fields.name!["en-US"],
      rgb: parseRGB(e.fields.rgb!["en-US"]),
      rgbString: e.fields.rgb!["en-US"],
      sysid: e.sys.id,
    };
  });
