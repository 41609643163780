import { Marked } from "@ts-stack/markdown";
import contentfulContent from "./contentfulData/data.json";

export interface WebsiteContent {
  // contentfulID
  cid: string;
  // user-defined ID
  uid: string;
  contentMarkdown: string;
}

export const websiteContents = contentfulContent.entries
  .filter(
    (e) =>
      e.sys.type === "Entry" && e.sys.contentType.sys.id === "websiteContent"
  )
  .map(
    (e): WebsiteContent => {
      return {
        cid: e.sys.id,
        uid: e.fields.uId!["en-US"],
        contentMarkdown: Marked.parse(e.fields.content!["en-US"]),
      };
    }
  );

export const readmeContent = websiteContents.find(
  (e) => e.uid === "color-shift-readme"
)!;
