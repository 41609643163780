import contentfulContent from "./contentfulData/data.json";

export interface Printshop {
  id: string;
  name: string;
  url: string;
  instagramHandle: string;
  dateAdded: string;
}

// TODO: use this for all the things
function getField(e: any, name: string) {
  return (e.fields[name] || { "en-US": "" })["en-US"];
}

export const printshops = contentfulContent.entries
  .filter(
    (e) => e.sys.type === "Entry" && e.sys.contentType.sys.id === "printshop"
  )
  .map((e) => {
    return {
      id: getField(e, "id"),
      name: getField(e, "name"),
      url: getField(e, "url"),
      instagramHandle: getField(e, "IG"),
      dateAdded: e.sys.createdAt,
    };
  });
