// ref: https://github.com/sindresorhus/multi-download/blob/master/index.js
// the repo above doesn't have typing

const delay = (ms: number) =>
  new Promise((resolve) => window.setTimeout(resolve, ms));
const download = async (url: string, name: string) => {
  const a = document.createElement("a");
  a.download = name;
  a.href = url;
  a.style.display = "none";
  document.body.append(a);
  a.click();

  // Chrome requires the timeout
  await delay(100);
  a.remove();
};

export default async function multiDownload(urls: string[], names: string[]) {
  for (const [index, url] of urls.entries()) {
    // rename only works for same-origin
    const name = names.length > index ? names[index] : "";
    await delay(index * 500);
    download(url, name);
  }
}
