import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import NotFoundPage from "@/views/404.vue";
import Home from "@/views/Home.vue";
import Sandbox from "@/views/Sandbox.vue";
import WebsitePage from "@/components/WebsitePage.vue";
import ColorList from "@/views/ColorList.vue";
import Printshops from "@/views/Printshops.vue";
import Profiles from "@/views/Profiles.vue";
import { websitePages } from "@/websitePages";

Vue.use(VueRouter);

const pageRoutes = websitePages.map(
  (p): RouteConfig => {
    return { path: `/${p.slug}`, component: WebsitePage, props: { page: p } };
  }
);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  { path: "/sandbox-testing", component: Sandbox },
  ...pageRoutes,
  { path: "/profiles", component: Profiles, props: { showPrivate: false } },
  {
    path: "/profiles/private",
    component: Profiles,
    props: { showPrivate: true },
  },
  { path: "/printshops", component: Printshops },
  { path: "/color-list", component: ColorList },
  { path: "*", component: NotFoundPage },
];

const router = new VueRouter({
  routes,
  mode: "history",
  base: process.env.BASE_URL,
  // ref: https://router.vuejs.org/guide/advanced/scroll-behavior.html
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
    if (savedPosition) {
      // back button
      return savedPosition;
    }
    // scroll to top
    return { x: 0, y: 0 };
  },
});

export default router;
