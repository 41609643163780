








import { Component, Prop, Vue } from "vue-property-decorator";
import { WebsitePage } from "@/websitePages";

@Component({})
export default class Home extends Vue {
  @Prop() page!: WebsitePage;
}
