













import { Component, Vue } from "vue-property-decorator";
import { readmeContent } from "@/websiteContent";
import { profiles, Profile } from "@/profiles";

function shuffle(a: any[]): any[] {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

@Component({})
export default class Home extends Vue {
  readmeContent = readmeContent.contentMarkdown;
  profiles = shuffle(profiles.filter((p) => p.status != "private")).slice(
    0,
    100
  );
}
