




















import { Component, Vue } from "vue-property-decorator";
import { printshops } from "@/printshops";
import { websiteContents } from "@/websiteContent";

@Component({})
export default class Home extends Vue {
  intro = websiteContents.find((w) => w.uid === "printshops-page-intro")!
    .contentMarkdown;

  outro = websiteContents.find((w) => w.uid === "printshops-page-outro")!
    .contentMarkdown;

  printshops = printshops.sort((a, b) => {
    if (a.dateAdded > b.dateAdded) return 1;
    if (b.dateAdded > a.dateAdded) return -1;
    return 0;
  });

  getURLPretty(url: string): string {
    return url
      .replace("http://", "")
      .replace("https://", "")
      .replace("www.", "")
      .replace(".com/", ".com");
  }
}
