import contentfulContent from "./contentfulData/data.json";

export interface ContentfulLink {
  sys: {
    id: string;
    linkType: "Entry" | "Asset";
  };
}

export interface Asset {
  sysid: string;
  url: string;
  fileName: string;
  contentType: string;
}

export function getField(e: any, name: string) {
  return (e.fields[name] || { "en-US": "" })["en-US"];
}

export const assetFiles = contentfulContent.assets.map((e) => {
  const file = getField(e, "file");
  return {
    sysid: e.sys.id,
    url: file.url,
    fileName: file.fileName,
    contentType: file.contentType,
  };
});

export const entries = contentfulContent.entries;
