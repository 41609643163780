import { RisoInk, risoInks } from "./risoInks";
import {
  entries,
  getField,
  ContentfulLink,
  assetFiles,
  Asset,
} from "./contentful";
import parse from "url-parse";

export class Profile {
  constructor(
    public sysid: string,
    public inks: RisoInk[],
    public status: "private" | "beta" | "public",
    public profileTypes: string[], // this can be {CMYK, ...}
    public iccSeparation: Asset,
    public iccPreview: Asset | null
  ) {}

  get isCMYK(): boolean {
    return this.profileTypes.includes("CMYK");
  }

  get name(): string {
    return this.inks.map((i) => i.name).join(" + ");
  }

  get inkSlots(): (RisoInk | null)[] {
    var out = [];
    this.inks.forEach((i) => out.push(i));
    for (var i = this.inks.length; i < 4; i++) {
      out.push(null);
    }
    return out;
  }

  private convertAssetURL(urlStr: string): string {
    const url = parse(urlStr);
    const out = "/downloads" + url.pathname;
    if (process.env.NODE_ENV === "development") {
      // no redirect in dev
      // console.log(
      //   "download renaming doesn't work in dev. in prod we will redirect download",
      //   { from: urlStr, to: out }
      // );
      return urlStr;
    }
    return out;
  }

  get downloadURLs(): string[] {
    const urls = this.iccPreview
      ? [this.iccSeparation.url, this.iccPreview.url]
      : [this.iccSeparation.url];
    return urls.map((u) => this.convertAssetURL(u));
  }

  get downloadNames(): string[] {
    if (this.status === "private") {
      return this.downloadURLs.map(
        (url) => url.split("/")[url.split("/").length - 1]
      );
    }
    return this.downloadURLs.map((_, index) => {
      return (
        [
          "[colorshift]",
          ...this.inks.map((i) => i.id),
          (this.isCMYK
            ? "- CMYK"
            : "- " + (index === 0 ? "separation" : "preview")) +
            // if this is beta - add a suffix
            (this.status === "beta" ? " (beta)" : ""),
        ]
          .filter((p) => p.length > 0)
          .join(" ") + ".icc"
      );
    });
  }
}

// these might include invalid profiles - filter those out below
const profilesAll = entries
  .filter(
    (e) =>
      e.sys.type === "Entry" && e.sys.contentType.sys.id === "colorShiftProfile"
  )
  .map((e) => {
    const sepLink = getField(e, "iccProfileSeparation") as ContentfulLink;
    const prevLink = getField(e, "iccProfilePreview") as ContentfulLink;
    const profileTypes = getField(e, "profileType") as string[] | undefined;

    if (!sepLink) {
      return null;
    }
    const sepFile = assetFiles.find((f) => f.sysid === sepLink.sys.id);
    if (!sepFile) {
      // this should only happen for draft profiles
      return null;
    }

    const out = new Profile(
      e.sys.id,
      getField(e, "risoInks").map((il: ContentfulLink) => {
        return risoInks.find((r) => r.sysid === il.sys.id)!;
      }),
      getField(e, "status"),
      profileTypes ? profileTypes : [],
      sepFile,
      prevLink
        ? (assetFiles.find((f) => f.sysid === prevLink.sys.id) as Asset)
        : null
    );
    return out;
  });

export const profiles = (profilesAll.filter((p) => p) as Profile[]).sort(
  (a, b) => {
    if (a.inks.length < b.inks.length) return -1;
    if (a.inks.length > b.inks.length) return 1;
    return 0;
  }
);
