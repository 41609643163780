














import { Component, Vue } from "vue-property-decorator";
import { risoInks } from "@/risoInks";

@Component({})
export default class ColorList extends Vue {
  risoInks = risoInks
    .sort((a, b) => {
      if (a.name > b.name) return 1;
      if (b.name > a.name) return -1;
      return 0;
    })
    .map((ink) => {
      return {
        formatted: `"${ink.name},RGB, ${ink.rgb.R}.000000 ${ink.rgb.G}.000000 ${ink.rgb.B}.000000"`,
        ...ink,
      };
    });
}
