import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Buefy from "buefy";
// @ts-ignore
import vueHeadful from "vue-headful";
import VueClipboard from "vue-clipboard2";

import "./assets/scss/app.scss";
import "fontsource-dm-mono";

Vue.use(Buefy, { defaultIconPack: "fas" });
Vue.use(VueClipboard);
Vue.component("vue-headful", vueHeadful);
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
